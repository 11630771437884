.ourprobg{
    background-image: url(../images/Howitworks.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 100px 0;
}
.ourproh{
font-family: 'Montserrat', sans-serif;
color: white;
font-size: 40px;
font-weight: 700;
line-height: 5px;
letter-spacing: 0em;
text-align: center;
text-transform: uppercase;
}
.ourprocolor{
    color: #85BB65;
}
.oshead{
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    font-size: 32px;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: 0em;
    text-align: left;
    
}
.osdis{
    font-family: 'Montserrat', sans-serif;
font-size: 16px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: justified;

}
.osbold{
    font-family: 'Montserrat', sans-serif;
font-size: 16px;
font-weight: 600;
line-height: 25px;
letter-spacing: 0em;
text-align: justified;
text-transform: uppercase;

}
.osboldgreen{

    color: #85BB65;
}
.centerbold{
    font-family: 'Montserrat', sans-serif;
font-size: 36px;
font-weight: 700;
line-height: 65px;
letter-spacing: 0em;
text-align: center;
text-transform: uppercase;
padding-bottom: 50px;

}
.teamhed{
    font-family: 'Montserrat', sans-serif;
font-size: 16px;
font-weight: 700;
line-height: 60px;
letter-spacing: 0em;
text-align: center;

}
.teamicon{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.teamincenter{
    display: flex;
    flex-direction: row;
    justify-content: center;
}


@media only screen and (max-width: 700px) {
    .osdis {
    padding-top: 20px;
    }
}