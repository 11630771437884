.form-control {
    background-color: #F8F8F8;
    border: none;
    padding: 10px;
    

}
.form-label{
    font-family: 'Montserrat', sans-serif;
font-size: 14px;
font-weight: 500;
line-height: 30px;
letter-spacing: 0em;
text-align: left;

}
.setformbgandpadd{
    background-color: white;
    padding: 55px;
    box-shadow:0px 4px 25px 0px rgba(0,0,0,0.05);
    margin-top: -65px;
}
.contactformbutton{
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    padding: 10px 40px;
    border: none;
    background: linear-gradient(94.21deg, #85BB65 5.59%, #B4F88B 111.36%);
    border-radius: 0px;
    border: none;
    float: right;
}
.formhead{
    font-family: 'Montserrat', sans-serif;
font-size: 24px;
font-weight: 700;
line-height: 20px;
letter-spacing: 0em;
text-align: center;
text-transform: uppercase;

}
.formdis{
    font-family: 'Montserrat', sans-serif;
font-size: 14px;
font-weight: 500;
line-height: 20px;
letter-spacing: 0em;
text-align: center;

}
.tarea{
    border: none;
    background-color: #F8F8F8;
    width: 100%;
}