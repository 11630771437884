.reghere{
text-decoration: underline;
font-weight: 800;
}
.regpopup{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
}
.regpopupinner{
    position: relative;
    padding: 40px 70px;
    width: 60%;
    max-width: 80%;
    background-color: #ffffff;
    border-radius: 10px;
}
.regalign{
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
}
.regrig{
width: 50%;
padding-right: 30px;
}
.reglef{
    width: 50%;
    padding-left: 30px;
}
.regtext{
    background-color: #F8F8F8;
    
}
.regformbutton{
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    padding: 10px 40px;
    border: none;
    background: linear-gradient(94.21deg, #85BB65 5.59%, #B4F88B 111.36%);
    display: flex;
width: 100%;
justify-content: center;
align-items: center;
}
.regl
{display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-around;
}
@media only screen and (max-width: 700px) {
    .regtext{
        padding: 20px;
    }
}
