.forgetpasswordpopup{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
}
.forgetpasswordpopupinner{
    position: relative;
    padding: 40px 150px;
    
    max-width: 54%;
    background-color: #ffffff;
    border-radius: 10px;
}
.regpopupinner{
    position: relative;
    padding: 10px;
    width: 100%;
    max-width: 100%;
    background-color: #ffffff;
    padding: 20px;
    margin: 40px;
    /* overflow-y: scroll; */
}
@media only screen and (max-width: 700px) {
    .popupinner{
        position: relative;
        top: 116px;
        padding: 10px;
        width: 100%;
        max-width: 100%;
        background-color: #ffffff;
        padding: 20px;
        margin: 40px;
        /* overflow-y: scroll; */
    }
}
