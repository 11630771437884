.signup{
    background-color: rgb(255, 255, 255);
    padding: 37px 72px 1px 78px;
}
.prehead{
    color: #000;
font-family: 'Montserrat', sans-serif;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: 60px; /* 250% */
text-transform: uppercase;
}
.predis{
    color: rgba(0, 0, 0, 0.80);
    font-family: 'Montserrat', sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 300;
line-height: 25px; /* 156.25% */
}
.signbtn{
    text-align: end;
    background-color: rgb(255, 255, 255);
    padding: 1px 26px 26px 1px;
}
.sign{
    background-color: rgb(255, 255, 255);
}
.Prefooter{
    margin: 100px 2px -59px 1px;
}
.prefooterbtn{
    
    font-size: 16px;
font-weight: 500;
text-align: center;
background: linear-gradient(140deg, #85BB65 0%, #B4F88B 100%);
border:none;
padding: 5px 50px;
color: #FFF;
font-family: 'Montserrat', sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 35px; /* 250% */
border-radius: 0px;
}
@media only screen and (max-width: 700px) {
    .Prefooter{
        margin: 2px 2px 0px 1px;
    }
    .signup{
        background-color: rgb(255, 255, 255);
        padding: 37px 10px 1px 10px;
    }
    .prefooterbtn{
        margin-top: 10px;
    }
    .prefooterbtn-padd{
        padding: 6px 59px 6px 59px;
    }
    
}