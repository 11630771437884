.popup{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
}
.popupinner{
    position: relative;
    padding: 40px 120px;
    max-width: 54%;
    background-color: #ffffff;
    border-radius: 10px;
}
.closebuttonpop, .closebuttonpop:hover{
    position: absolute;
    top:16px;
    right: 16px;
    background-color: transparent;
    color: black;
    font-size: 20px;
    font-weight: 500;
    border: none;
}
.pressformhrading{
    font-family: 'Montserrat', sans-serif;
    color: #58B065; 
    font-size: 30px;
    line-height: 40px;
    font-weight: 700;
    margin-bottom: 30px;
    text-align:center;
}
.inquiryformdescription{
    font-family: 'Montserrat', sans-serif;
    color:#000000;
    font-size: 16px;
    line-height: 23px;
    font-weight: 400;
    text-align:center;
}
.inquiryformbutton{
    color: #000000;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 15px;
    font-weight: 500;
    background-color: transparent;
    border: 1px solid #000000;
    border-radius: 10px;
    padding: 10px 20px;
}
.inquiryformbutton:hover, .inquiryformbutton:focus{
    color: #ffffff;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 15px;
    font-weight: 500;
    background-color: #58B065;
    border: 1px solid #58B065;
    border-radius: 10px;
    padding: 10px 20px;
}
.makeinquiryformburttoncenter{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}
.inquiryfield{
    background-color: transparent !important;
    color: #000000;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    border: 1px solid black;
}
@media only screen and (max-width: 600px) {
    .pressformhrading{
        font-family: "futura";
        color:#58B065;
        font-size: 22px;
        line-height: 25px;
        font-weight: 700;
        margin-bottom: 10px;
        margin-top: 0px;
        text-align:center;
    }
    .inquiryfield{
        background-color: transparent !important;
        color: #000000;
        font-family: 'Montserrat', sans-serif;
        font-size: 13px;
        border: 1px solid black;
    }
    .makeinquiryformburttoncenter{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
    }
    .popup{
        position: fixed;
        top: 20;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.2);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .popupinner{
        position: relative;
        padding: 10px;
        width: 100%;
        max-width: 100%;
        background-color: #ffffff;
        padding: 20px;
        margin: 40px;
        /* overflow-y: scroll; */
    }
    .closebuttonpop, .closebuttonpop:hover{
        position: absolute;
        top:0px;
        right: 2px;
        background-color: transparent;
        color: black;
        font-size: 20px;
        font-weight: 500;
        border: none;
    }
    .inquiryformbutton{
        font-size: 13px;
        line-height: 17px;
    }
    .inquiryformbutton:hover{
        font-size: 13px;
        line-height: 17px;
    }
}



.loginhead{
    font-family: 'Montserrat', sans-serif;
font-size: 24px;
font-weight: 700;
line-height: 20px;
letter-spacing: 0em;
text-align: center;
text-transform: uppercase;
}
.forgetpass{
    color: #5E7F4B;
    font-family: 'Montserrat', sans-serif;
font-size: 16px;
text-align: right;
font-style: normal;
font-weight: 500;
line-height: 30px; /* 187.5% */
}
.loginformbutton{
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    padding: 10px 40px;
    border: none;
    background: linear-gradient(94.21deg, #85BB65 5.59%, #B4F88B 111.36%);
    display: flex;
width: 100%;
justify-content: center;
align-items: center;
}
.loginformtext{
    color: rgba(0, 0, 0, 0.58);
text-align: center;
font-family: 'Montserrat', sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 30px; /* 187.5% */
}