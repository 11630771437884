.footer{
    background-color: black!important;
    background: url(../images/Footer_bg.png);
    background-size: auto;
    background-position: right;
    background-repeat: no-repeat;
    padding: 100px 100px;

}
.divider{
    color: rgba(255, 255, 255, 0.5);
    margin-top: 36px;

}
.LogoFooter{
    margin-bottom: 44px;
}
.mb-4{
    color: #FFF;
    font-family: 'Montserrat', sans-serif;
font-size: 20px;
font-weight: 600;
}
.footerlink{
    color: #ffffff;
    font-family: 'Montserrat', sans-serif;
font-size: 16px;
font-weight: 400;
line-height: 25px; /* 156.25% */
}
.footerlinkd{
    color: #FFF;
    font-family: 'Montserrat', sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 300;
line-height: 35px; /* 250% */

}
.anch{
    text-decoration: none;
}
.footerlinkh{
    color: #FFF;
    font-family: 'Montserrat', sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 35px; /* 250% */}

.prefooterbtn{
    margin-left: 10px;
}
@media only screen and (max-width: 700px) {
    .footer{
        background-color: black!important;
        background: url(../images/Footer_bg.png);
        background-size: auto;
        background-position: right;
        background-repeat: no-repeat;
        padding: 0px 10px;
    
    }
    .margsetlogoformbl{
        margin-top: 20px;
    }
}