.greenbroker{
    background: linear-gradient(101.58deg, #85BB65 21.54%, #C3EAC9 104.39%);
    box-shadow: 0px 4px 25px 0px #0000000D;
    padding: 100px 150px; 
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    font-weight: 600;
    border: none;
    line-height: 52px;
    letter-spacing: 0em;
    text-transform: uppercase;




}
.whiteinverster{
background: linear-gradient(101.58deg, #ffffff 21.54%, #ffffff 104.39%);
    box-shadow: 0px 4px 25px 0px #0000000D;
    padding: 100px 150px; 
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    font-weight: 600;
    border: none;
    line-height: 52px;
    letter-spacing: 0em;
    text-transform: uppercase;
    color: black;

}
.greenwhitealign{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.spadding{
    padding: 100px 0;
    
}
.sbgdot{
    background-image: url(../images/dot2.png);
}
.successhead{
    color: #000;
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 52px; /* 216.667% */
    text-transform: uppercase;
}
.successdis{
    color: #000;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 52px; /* 325% */
}
.successpop{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.regtextbox{
    color: rgba(0, 0, 0, 0.60);
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 214.286% */
}
.regtext{
    padding: 40px 30px 200px 30px;
}
@media only screen and (max-width: 600px){
    .whiteinverster, .greenbroker{
        padding: 20px 20px; 
    }
   .regtext{
    padding: 40px 30px 20px 30px;
}
}