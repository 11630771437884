.privacydis{
    
    font-size: 24px;
    font-weight: 500;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
}
.marrgintop{
padding: 100px 0;
}
.pggreen{
    font-family: 'Montserrat', sans-serif;
font-size: 24px;
font-weight: 500;
line-height: 45px;
letter-spacing: 0em;
text-align: center;
color: #85BB65;
;
}
