.navbar{
    padding: 1% 5% !important;
}
.navbar-brand {
    width: 30%;
}
.navbar-nav{
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-evenly !important;
    width: 75%;
}
.nav-link{
    font-family: 'Montserrat', sans-serif;
    color: #85BB65CC;
    font-weight: 500;
}
.App{
    background: #FFF;
box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.05);
}
button.menubtn.btn.btn-outline-success {
    color: #FFF;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    background: linear-gradient(140deg, #85BB65 0%, #B4F88B 100%);
    
    height: 43px;
    width: 182px;
    border: none;
    border-radius: 0px;
}
.menubutton{
    font-size: 16px;
font-weight: 500;
text-align: center;
background: linear-gradient(140deg, #85BB65 0%, #B4F88B 100%);
border:none;
padding: 10px 30px;
color: #FFF;
font-family: 'Montserrat', sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 35px; /* 250% */
border-radius: 0px;
}

@media only screen and (max-width: 700px) {
    .navbar-nav{
        display: flex !important;
        flex-direction: column !important;
        justify-content: space-evenly !important;
        width: 100%;
    }
    .navbar-nav{
        max-height: 190px!important;
    }
}