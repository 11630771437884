.ourprobg{
    background-image: url(../images/Howitworks.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 100px 0;
}
.ourproh{
font-family: 'Montserrat', sans-serif;
color: white;
font-size: 40px;

font-weight: 700;
letter-spacing: 0em;
text-align: center;
}
.ourprocolor{
    color: #85BB65;
}
.udpadding{
    padding: 50px 0;
}
/* .cyberbonds{
    
} */
.head{
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    color: black;
font-size: 24px;
font-weight: 700;
line-height: 60px;
letter-spacing: 0em;
text-align: left;

}
.discrption{
    font-family: 'Montserrat', sans-serif;
    color: black;
font-size: 16px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: justified;

}
.oursol{
     font-family: 'Montserrat', sans-serif;
font-size: 32px;
font-weight: 700;
line-height: 60px;
letter-spacing: 0em;
text-align: left;

}
.productcol
{
    padding: 5%;
    box-shadow:0px 4px 25px 0px rgba(0,0,0,0.05);
    background-color: white;
    margin-top: 25px;
    height: 328px;
}
.productcol-padd
{
    padding:  5% 5% 10% 5%;
    box-shadow:0px 4px 25px 0px rgba(0,0,0,0.05);
    background-color: white;
    margin-top: 25px;
}

.Prohead{
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    padding-top: 15px;
    text-transform: uppercase;
    
}
.Prodisc{
    font-family: 'Montserrat', sans-serif;
font-size: 14px;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: justified;

}
/* .proboxbg{
background-image: url(../images/proboxbg.png);
background-repeat: no-repeat;
background-position: right;
background-size: contain;
}
.proboxbg1{
    background-image: url(../images/proboxbg.png);
    background-repeat: no-repeat;
    background-position: left;
    background-size: contain;
    } */
.dotbg{
    background-image: url(../images/dot2.png);
    background-repeat: repeat;
}

@media only screen and (max-width: 700px) {
    .ourproh{
        line-height: 40px!important;
    }
}
@media only screen and (max-width: 1200px) {
    .Producthead1{
        font-size: 16px;
    }
    .Producthead2{
        font-size: 16px;
    }
}