
.heroheading{
    font-family: 'Montserrat', sans-serif;

    font-size: 40px;
    font-weight: 800;
    line-height: 60px;
    letter-spacing: 0em;
    text-align: left;
    
    

}
.setpaddforcoldprod{
    padding: 65px 0px;
}
.bgr{
    background-image: url(../images/BGr.png);
    background-repeat: no-repeat;
    background-size: cover;
    margin: 87px 0;
}

.bg{
    background-image: url(../images/dot2.png);
    background-repeat: repeat;
}
.greenbgcold{
padding: 7% 0%;
}
.productall{
    padding-top: 67px;
}
.coldproducthead{
    font-family: 'Montserrat', sans-serif;
     font-size: 32px;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: 0em;
    text-align: center;
    padding-top: 40px;
    padding-bottom: 40px;
}
.coldproducthead1{
    font-family: 'Montserrat', sans-serif;
     font-size: 32px;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: 0em;
    text-align: center;
    
}
.Producthead1{
    text-align: center;
    font-family: 'Montserrat', sans-serif;
font-size: 20px;
font-weight: 600;
letter-spacing: 0em;
text-align: center;
color: #85BB65;

}
.Producthead2{
    text-align: center;
    font-family: 'Montserrat', sans-serif;
font-size: 20px;
font-weight: 600;
letter-spacing: 0em;
text-align: center;
color: #263A29;

}
.herodis{
    font-family: 'Montserrat', sans-serif;
font-size: 20px;
font-weight: 300;
line-height: 40px;
letter-spacing: 0em;
text-align: left;


}
.herobtn{
    font-size: 16px;
font-weight: 500;
text-align: center;
background: linear-gradient(140deg, #85BB65 0%, #B4F88B 100%);
border:none;
padding: 5px 70px;
color: #FFF;
font-family: 'Montserrat', sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 40px; /* 250% */
border-radius: 0px;

}
.Heading{
    display: flex;
    align-items: center;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
}
.heroimg{
    display: flex;
    flex-direction: column;
    align-items: flex-end;

}
.green{
    font-family: 'Montserrat', sans-serif;
    color: #ffffff;
    font-size: 40px;
font-style: normal;
font-weight: 900;
line-height: 60px; /* 150% */
text-transform: uppercase;
}
.ourmissionpadding{
    padding-top: 125px;
    padding-bottom: 125px;
}
.ourmissionalign{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.heroheading{
    font-family: 'Montserrat', sans-serif;
    color: #000000;
font-size: 40px;
font-style: normal;
font-weight: 900;
line-height: 60px; /* 150% */
text-transform: uppercase;
}
.ourmissionhead{
    font-family: 'Montserrat', sans-serif;
font-size: 40px;
font-weight: 700;
line-height: 60px;
letter-spacing: 0em;
text-align: left;

}
.ourmissiondis{
    font-family: 'Montserrat', sans-serif;
    color: #ffffff;
font-size: 20px;
font-weight: 500;
line-height: 40px;
letter-spacing: 0em;
text-align: left;

}
.lightgreen{
    background-color: rgba(195, 234, 201, 0.2);
    
}
.iconboxhead{
    font-family: 'Montserrat', sans-serif;
font-size: 32px;
font-weight: 700;
line-height: 50px;
letter-spacing: 0em;
text-align: left;

}
.iconbox{
    color: 
    #85BB65;
}
.iconboxtext{
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    /* text-align: left; */
    padding-left: 12px;
    
}
.pama{
    padding: 120px 0;
    margin: 100px 0;
}
.centergreenbox{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.icontext1{
    display: flex;
    flex-direction: row;
}
.courproductdic{
    font-family: 'Montserrat', sans-serif;
font-size: 20px;
font-weight: 500;
line-height: 40px;
letter-spacing: 0em;
text-align: center;


}
.sevhead{
    font-family: 'Montserrat', sans-serif;
font-size: 32px;
font-weight: 700;
line-height: 60px;
letter-spacing: 0em;
text-align: left;
text-transform: uppercase;
padding-left: 3%;


}
.sevheadgreen{
    color: #85BB65;
}
.icond{
    color: rgba(0, 0, 0, 0.71);
text-align: justify;
font-family: 'Montserrat', sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 214.286% */
}
.icon1{
    padding: 9%;
}
.iconh{
    color: #000;
text-align: left;
font-family: 'Montserrat', sans-serif;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 30px; /* 300% */
text-transform: uppercase;
padding-top: 10px;


}
.containercolor{
    background-color: #F3FBF4;
    margin-top: 100px;
    margin-bottom: 100px;
    padding-top: 60px;
    padding-bottom: 28px
}
.bottomh{
    font-family: 'Montserrat', sans-serif;
font-size: 40px;
font-weight: 700;
line-height: 62px;
letter-spacing: 0em;
text-align: left;
text-transform: uppercase;

}
.bottompad{
    padding-bottom: 50px;
}

.Bottomimg{
    display: flex;
    flex-direction: column;
  justify-content: center;
  padding-right: 133px;
}
.bottomd{
    font-family: 'Montserrat', sans-serif;
font-size: 16px;
font-weight: 300;
line-height: 25px;
letter-spacing: 0em;
text-align: left;

}
.bottomgreen{
color: #85BB65;
}
.productbg{
    padding: 30px;
    background-color: #151A12;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url(../images/bg.png);
    background-repeat: no-repeat;
    background-position-x: 227px;
    background-position-y: 24px;
}
.productbg1{
    padding: 30px;
    background-color: #E1FAD3;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.ournission{
    background-image: url(../images/ourmission.png);
    background-repeat: no-repeat;
    background-size: cover;
}
.howitworks{
    background-image: url(../images/Howitworks.png);
    background-repeat: no-repeat;
    background-size: cover;
}
.herobg{
    background-image: url(../images/Unionbg.png);
    background-repeat: no-repeat;
    background-position-y: 138px;
    background-position-x: 100px;
    padding: 100px 0;
}
.greencolortext{
    color: #85BB65;
}
.greenbgcold{
    padding: 30px;
    background-color: #E1FAD3;
    
}
.blackbgcold1{
    padding: 30px;
    background-color: #151A12;
}
.blackbgcold2{
    padding: 30px;
    background-color: #151A12;
}

.blackbgcold2{
    padding: 30px;
    background-color: #151A12;
}
.fieldhead1{
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
    margin-bottom: 0px;
}
.fieldhead2{
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
    margin-bottom: 0px;
}
.addgapbetweenblocscold{
    display: flex;
}
.fieldhead3{
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
    margin-bottom: 0px;
}
.iconbgfield{
    background-color: #DBFFC638;
    padding: 10px;
    width: 40px;
    height: 40px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.iconbgfield{
    font-size: 22px;
    color:rgba(219, 255, 198, 0.22);
    margin-top: -5px;
}
.makebothiconandtextinrow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items:flex-end
}
.makeimageandiconinrow1{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 20px;
}
.makeimageandiconinrow3{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 20px;
}
.makeimageandiconinrow4{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 20px;
}
.makeimageandiconinrow2{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
}
@media only screen and (max-width: 700px) {
    .blackbgcold1{
        margin-top: 20px;
    }
    .greenbgcold{
        margin-top: 20px;
    }
    .blackbgcold2{
        margin-top:20px;
    }
    .productbg1{
        margin-top:20px
    }
    .makeimageandiconinrow1{
        padding-bottom: 0px;
    }
    .Bottomimg{
        
        margin-bottom: 20px;
        width: 100%;
        padding-right: 1px;
    }
    .heroimg{
        padding-top: 40px;
    }
    .ourmissionalign{
        align-items: center;
    }
    .ourmissiondis{
        text-align: center;
 

    }
    .heroheading {
        font-size: 27px;
        line-height: 40px;

    }
    .herodis {
        font-size: 16px;
    }
    

    .bottomh{
        font-size: 27px;
        line-height: 40px;
    }
}
@media only screen and (max-width: 1400px)  {
    .blackbgcold1{height: 236px;}
    .blackbgcold2{height: 236px;}
}
@media only screen and (max-width: 1200px){
.fieldhead1{
    font-size:13px;
}
.fieldhead2{
    font-size:13px;
}
.fieldhead3{
    font-size:13px;
}
}
@media only screen and (max-width: 992px) and (min-width: 700px){
.tab{
    width: 50%;
    
}
.blackbgcold1{
    margin-top: 20px;
}
.blackbgcold2{
    margin-top: 20px;

}
.martp{
    margin-top: 18px;
}
}